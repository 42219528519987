<template>
  <div id="wrap">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>


  export default {
    name      : 'BlankLayout',
    components: {
      
    },
  }
</script>

<style>
</style>
